import React from 'react'
import { Link, graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import ServiceSection from '../components/ServiceSection'
import Check from 'react-feather/dist/icons/check'

// Export Template for use in CMS preview
export const HomePageTemplate = ({ 
  title, 
  body }) => (
    <section className="section thick">
    <div className="container skinny taCenter">
      <p>
        <Check size="5rem" />
      </p>
      <h1>{title}</h1>

      <p>
        <Content source={body} />
        <br />
        Zpět na{' '}
        <Link to="/">Úvodní stránku</Link>
      </p>
    </div>
  </section>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} services={page.frontmatter.services}/>
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ThankYouPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        featuredImage
      }
    }
  }
`
